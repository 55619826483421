var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"tile":"","transition":"slide-x-transition","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"tile":"","small":_vm.$vuetify.breakpoint.mdAndDown,"text":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"tw-text-xl tw-mr-1"},[_vm._v("mdi-account-outline")]),_c('span',{staticClass:"tw-capitalize tw-tracking-normal"},[_vm._v(_vm._s(_vm.userCurrent))])],1)]}}])},[_c('v-list',{attrs:{"dense":"","flat":""}},[(_vm.role === 2)?_c('v-list-item',[_c('v-btn',{attrs:{"block":"","text":"","to":{ name: 'user-profile' }}},[_c('v-icon',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? 'tw-text-lg tw-mr-2'
              : 'tw-text-sm tw-mr-2'},[_vm._v("mdi-card-account-details-outline")]),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? 'tw-capitalize tw-tracking-normal tw-text-sm tw-flex tw-items-center'
              : 'tw-capitalize tw-tracking-normal tw-text-xs tw-flex tw-items-center'},[_vm._v("Profile")])],1)],1):_vm._e(),_c('v-list-item',{staticClass:"tw-px-0"},[_c('v-btn',{attrs:{"block":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.handleLogout($event)}}},[_c('v-icon',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? 'tw-text-lg tw-mr-2'
              : 'tw-text-sm tw-mr-2'},[_vm._v("mdi-logout-variant")]),_c('span',{class:_vm.$vuetify.breakpoint.mdAndUp
              ? 'tw-capitalize tw-tracking-normal tw-text-sm tw-flex tw-items-center'
              : 'tw-capitalize tw-tracking-normal tw-text-xs tw-flex tw-items-center'},[_vm._v("Logout")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }