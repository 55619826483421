<template>
  <v-app>
    <navigation-component />
    <v-main class="tw-bg-gray-100">
      <loading-overlay :loading="loading" />
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import page from "../mixins/page";
import LoadingOverlay from "../components/LoadingOverlay.vue";
import NavigationComponent from "../components/NavigationComponent.vue";

export default {
  mixins: [page],
  components: {
    NavigationComponent,
    LoadingOverlay,
  },
  computed: {
    loading () {
      return this.$store.state.auth.loading
    }
  }
};
</script>
