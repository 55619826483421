<template>
  <div>
    <v-app-bar app color="white" class="tw-shadow-md">
      <v-app-bar-nav-icon
        @click="show = !show"
        class="tw-text-primary"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        :class="
          $vuetify.breakpoint.mdAndDown
            ? 'text-capitalize tw-text-sm'
            : 'text-capitalize'
        "
        >{{ title }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <menu-profile />
    </v-app-bar>
    <v-navigation-drawer v-model="show" app color="#001529" width="270">
      <v-list dark>
        <v-list-item>
          <v-list-item-title class="headline">Dashboard</v-list-item-title>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list nav dense v-for="(item, i) in navigations" :key="i">

        <v-subheader
          dark
          v-text="item.subheader"
          class="text-capitalize tw-text-sm"
          v-if="item.role.indexOf(role) !== -1"
        ></v-subheader>

        <v-list-item-group v-for="(nav, ic) in item.items" :key="ic">
          <v-list-item
            active-class="tw-bg-primary"
            :to="{ name: nav.link }"
            v-if="nav.role.indexOf(role) !== -1"
          >
            <v-list-item-icon>
              <v-icon class="tw-text-white" v-text="`mdi-${nav.icon}`"></v-icon>
            </v-list-item-icon>
            <v-list-item-title
              v-text="nav.text"
              class="text-capitalize tw-text-white tw-tracking-wide"
            >
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import page from "../mixins/page";
import MenuProfile from "./MenuProfile.vue";

export default {
  components: { MenuProfile },
  mixins: [page],
  data() {
    return {
      show: null,
      navigations: [
        {
          subheader: "Property",
          role: [1, 2],
          items: [
            {
              icon: "file",
              text: "project",
              link: "project",
              role: [1, 2],
            },
            {
              icon: "home",
              text: "Property Detail",
              link: "property-detail",
              role: [1, 2],
            },
            {
              icon: "image-multiple",
              text: "Photo Property",
              link: "photo-detail-property",
              role: [1, 2],
            },
          ],
        },
        {
          subheader: "user",
          role: [1, 2],
          items: [
            {
              icon: "account-cog",
              text: "developer",
              link: "developer",
              role: [1],
            },
            {
              icon: "account-details",
              text: "visitor",
              link: "visitor",
              role: [1],
            },
            {
              icon: "store",
              text: "booth coordinate",
              link: "booth-coordinate",
              role: [1],
            },
            {
              icon: "store",
              text: "booth",
              link: "booth",
              role: [2],
            },
            {
              icon: "account-box",
              text: "profile",
              link: "user-profile",
              role: [2],
            },
          ],
        },
        {
          subheader: "analytic",
          role: [1],
          items: [
            {
              icon: "information",
              text: "tracking",
              link: "tracking",
              role: [1],
            },
          ],
        },
      ],
    };
  },
  computed: {
    role() {
      return parseInt(this.$store.state.auth.profile.role_id);
    },
  },
};
</script>

<style></style>
