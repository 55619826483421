<template>
  <v-menu tile transition="slide-x-transition" bottom offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        tile
        :small="$vuetify.breakpoint.mdAndDown"
        text
        depressed
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="tw-text-xl tw-mr-1">mdi-account-outline</v-icon>
        <span class="tw-capitalize tw-tracking-normal">{{ userCurrent }}</span>
      </v-btn>
    </template>
    <v-list dense flat>
      <v-list-item v-if="role === 2">
        <v-btn block text :to="{ name: 'user-profile' }">
          <v-icon
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'tw-text-lg tw-mr-2'
                : 'tw-text-sm tw-mr-2'
            "
            >mdi-card-account-details-outline</v-icon
          >
          <span
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'tw-capitalize tw-tracking-normal tw-text-sm tw-flex tw-items-center'
                : 'tw-capitalize tw-tracking-normal tw-text-xs tw-flex tw-items-center'
            "
            >Profile</span
          >
        </v-btn>
      </v-list-item>
      <v-list-item class="tw-px-0">
        <v-btn block text @click.prevent="handleLogout">
          <v-icon
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'tw-text-lg tw-mr-2'
                : 'tw-text-sm tw-mr-2'
            "
            >mdi-logout-variant</v-icon
          >

          <span
            :class="
              $vuetify.breakpoint.mdAndUp
                ? 'tw-capitalize tw-tracking-normal tw-text-sm tw-flex tw-items-center'
                : 'tw-capitalize tw-tracking-normal tw-text-xs tw-flex tw-items-center'
            "
            >Logout</span
          >
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { logout } from "@/api/auth";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      navigations: [
        {
          subheader: "user",
          items: [
            {
              icon: "account",
              text: "developer",
              link: "developer",
            },
          ],
        },
        {
          subheader: "analytic",
          items: [
            {
              icon: "account",
              text: "tracking",
              link: "dashboard",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async handleLogout() {
      try {
        await Swal.fire({
          title: "Yakin ingin logout ?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ya",
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("auth/setLoading");
            await logout();
            this.$store.dispatch("auth/setLoading");
            this.errors = {};
            this.$store.dispatch("auth/removeCurrentUser");
            this.$router.push({ name: "login" });
          }
        });
      } catch (error) {
        this.$store.dispatch("auth/setLoading");
        if (error.response) {
          this.errors = error.response.data.errors;
        } else {
          console.log(error);
        }
      }
    },
  },
  computed: {
    role() {
      return this.$store.state.auth.profile.role_id;
    },
    userCurrent() {
      return this.$store.state.auth.profile.name;
    },
  },
};
</script>

<style></style>
